import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { clone, element } from "../util/Utils";
import { updatePosition, updateStatus } from "../tracking/FeatureTracking";
import { AbstractFacetValue } from "./AbstractFacetValue";
import StringHelper from "../util/StringHelper";

const FACET_VALUE_CLASS = `find_facetValue`;
const POPULAR_FACET_VALUE_DUPLICATE_CLASS = `find_facetValue--popularDuplicate`;
const FACET_VALUE_FAVORITE_ICON_CLASS = `${FACET_VALUE_CLASS}--favoriteIcon`;
const FACET_VALUE_PERSONALIZATION_BANNER_MARGIN_CLASS = `${FACET_VALUE_CLASS}--personalizationBannerMargin`;

export class FacetValue extends AbstractFacetValue {
  static readonly factory = HeurekaElementFactory.byElement("label", FACET_VALUE_CLASS, FacetValue);
  readonly label: HTMLLabelElement;
  readonly input: HTMLInputElement | null;
  readonly span: HTMLSpanElement | null;

  /*               */
  constructor(
    readonly facetValue: HTMLLabelElement,
    readonly liElem: HTMLLIElement | null = facetValue.closest("li"),
  ) {
    super();
    this.label = facetValue;
    this.input = facetValue.querySelector("input");
    this.span = facetValue.querySelector(".find_facetValue__label");
  }

  /*                  */
  static facetValue(facetId: string, facetValueId: string, root?: ParentNode) {
    const facetValueInput = element(
      `#facetValue_${facetId}_${StringHelper.regexEscapeGreaterThan(facetValueId)}`,
      root,
    );
    return FacetValue.factory.declare(facetValueInput?.closest("label"));
  }

  static fromId(facetValueId?: string, root?: ParentNode) {
    const facetValueInput = facetValueId
      ? element(`#${StringHelper.regexEscapeGreaterThan(facetValueId)}`, root)
      : null;
    return FacetValue.factory.declare(facetValueInput?.closest("label"));
  }

  static fromIds(facetValueIds: string[], root?: ParentNode) {
    return facetValueIds.map((f) => FacetValue.fromId(f, root)).flatMap((f) => (f ? [f] : []));
  }

  /*               */
  /*                       */

  get li(): HTMLLIElement | null {
    return this.facetValue.closest("li");
  }

  get id(): string | undefined {
    return this.input?.id;
  }

  get name(): string | undefined {
    return this.input?.name;
  }

  get value(): string | undefined {
    return this.input?.value;
  }

  get checked(): boolean {
    if (this.input) {
      return this.input?.checked;
    }
    return false;
  }

  set checked(checked: boolean) {
    if (this.input) {
      this.input.checked = checked;
    }
  }

  markChecked() {
    if (this.input) {
      this.input.checked = true;
      this.input.defaultChecked = true;
      updateStatus(this.input, "selected", 2);
    }
  }

  get title(): string {
    return this.facetValue.title;
  }

  get spanInnerHTML(): string | null | undefined {
    return this.span?.innerHTML;
  }

  set spanInnerHTML(innerHTML: string | null | undefined) {
    if (this.span && innerHTML) {
      this.span.innerHTML = innerHTML;
    }
  }

  set position(position: number) {
    const input = this.input;
    if (input) {
      updatePosition(input, position);
    }
  }

  clearPosition() {
    this.position = 0;
    return this;
  }

  get hiddenPopularValueDuplicate(): boolean {
    return this.li?.classList.contains(POPULAR_FACET_VALUE_DUPLICATE_CLASS) || false;
  }

  set hiddenPopularValueDuplicate(hide) {
    if (this.li) {
      this.li.classList.toggle(POPULAR_FACET_VALUE_DUPLICATE_CLASS, hide);
    }
  }

  get offsetTop() {
    return this.li?.offsetTop;
  }

  set favoriteIconVisibility(visible: boolean) {
    this.facetValue.classList.toggle(FACET_VALUE_FAVORITE_ICON_CLASS, visible);
    if (this.icon) {
      this.icon.hidden = !visible;
    }
  }

  get icon(): HTMLSpanElement | null {
    return this.facetValue.querySelector(`.find_facetValue__icon`);
  }

  setBannerMargin(isBannerMargin: boolean, marginBottomValue?: number) {
    this.li!.style.marginBottom = `${marginBottomValue}px`;
    this.li?.classList.toggle(FACET_VALUE_PERSONALIZATION_BANNER_MARGIN_CLASS, isBannerMargin);
  }

  public addBannerMargin(marginBottomValue: number) {
    this.setBannerMargin(true, marginBottomValue);
  }

  public removeBannerMargin() {
    this.setBannerMargin(false, 0);
  }

  public static ids(values: FacetValue[]): string[] {
    return values.flatMap((f) => (f.id ? [f.id] : []));
  }

  public static isFacetValueUserInteraction(event: Event) {
    return (
      event.target instanceof HTMLElement &&
      event.target.closest(".find_facetValue__label, .find_facetValue__switch, [type=submit]")
    );
  }

  transformToFacetValueTag() {
    this.transformToChip();
    const closeIcon = document.createElement("svg");
    closeIcon.classList.add("pl_icon50", "pl_icon_close");
    closeIcon.setAttribute("role", "img");
    const useElem = document.createElementNS("http:/*                           */
    useElem.setAttributeNS(
      "http:/*                     */
      "xlink:href",
      "/assets-static/icons/pl_icon_close50.svg#pl_icon_close50",
    );
    closeIcon.append(useElem);
    this.label?.append(closeIcon);
    this.input?.classList.add("find_facetValueTag__deselect");
    this.label?.classList.remove("find_facetValue");
    this.span?.classList.remove("find_facetValue__label");
    return this;
  }

  clone(): FacetValue {
    return FacetValue.factory.create(clone(this.label));
  }
}
