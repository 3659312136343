import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { isActive } from "../toggle/Toggle";

const FACET_VALUE_TAG_CLASS = `find_facetValueTags__item`;
const FACET_VALUE_TAG_DESELECT_CLASS = `find_facetValueTag__deselect`;
const FACET_VALUE_TAG_DESELECT_SELECTOR = `.${FACET_VALUE_TAG_DESELECT_CLASS}`;
const FACET_VALUE_TAG_FAVORITE_ICON_CLASS = ".heureka_facetValueTag--favoriteIcon";

export class FacetValueTag {
  static readonly factory = HeurekaElementFactory.byElement("li", FACET_VALUE_TAG_CLASS, FacetValueTag);

  /*               */
  constructor(readonly facetValueTag: HTMLLIElement) {}

  /*                  */

  /*               */

  /*                       */

  /**
 *
 */
  get button(): HTMLButtonElement | null {
    return this.facetValueTag.querySelector("button");
  }

  /*                                                                                                         */
  get deselect(): HTMLElement | null {
    return this.facetValueTag.querySelector(FACET_VALUE_TAG_DESELECT_SELECTOR);
  }

  get tsFeatureRef(): string | undefined {
    return isActive("HEUREKA_1367_REFACTOR_FACET_VALUE_TAGS")
      ? this.deselect?.dataset.tsFeatureRef
      : this.button?.dataset.tsFeatureRef;
  }

  private get facetValueId(): string | undefined {
    return this.tsFeatureRef?.substring("facetValue_".length);
  }

  get span(): HTMLSpanElement | null {
    return this.facetValueTag.querySelector("label span");
  }

  get title(): string | null {
    return this.span?.textContent || null;
  }

  get favoriteIcon(): HTMLElement | null {
    return this.facetValueTag.querySelector(FACET_VALUE_TAG_FAVORITE_ICON_CLASS);
  }

  set favoriteIconVisibility(visible: boolean) {
    if (this.favoriteIcon) {
      this.favoriteIcon.hidden = !visible;
    }
  }

  /*                                                                                 */
  get isCategoryPathFacetValueTagForm(): boolean | undefined {
    return this.facetValueId?.startsWith("kategorien_") && !!this.facetValueTag.querySelector("form");
  }
}
