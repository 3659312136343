import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { allElements, appendAll, clear, isProductList, prependElement } from "../util/Utils";
import { FilterSorting } from "./FilterSorting";
import { FilterTitleClickAction } from "./FilterTitleClickAction";
import { SubmitOnChangeListener } from "./SubmitOnChange";
import { fireFilterSubmit } from "./FilterFormActions";
import { SortingForm } from "../sorting/SortingForm";
import { FilterTitle } from "./FilterTitle";
import type { FilterSortingOld } from "./FilterSortingOld";
import { isActive } from "../toggle/Toggle";
import { isActiveVariation } from "../experiment/Experiments";

const MOBILE_FILTERS_ID = "heureka_filterTitles";
const SELECTOR_DENSE = ".find_filterTitles";
const SELECTOR_EXPANDED = ".find_filterTitles--expanded";
const FILTER_ITEM_SELECTOR = "div[data-filter-id]";

export class FilterTitles {
  private static readonly factory = HeurekaElementFactory.byClass("heureka_filterTitles", FilterTitles);
  private static readonly factoryOld = HeurekaElementFactory.byId(MOBILE_FILTERS_ID, FilterTitles);

  /*               */
  constructor(
    readonly elem: HTMLElement,
    private readonly filters: HTMLElement[] = FilterTitles.selectFilters(elem),
    readonly filterTitles: FilterTitle[] = FilterTitle.factory.all(elem),
  ) {}

  private static selectFilters(elem: HTMLElement) {
    return Array.from(allElements(FILTER_ITEM_SELECTOR, elem));
  }

  /*                  */

  static template(rootElement?: ParentNode | null) {
    if (
      (isActive("HEUREKA_1338_CRISPY_FILTER_LISTS_E3213") || isActive("HEUREKA_1338_CRISPY_FILTER_LISTS")) &&
      isActiveVariation("e3213", "TestGroup") &&
      !isProductList()
    ) {
      return FilterTitles.factory.pick(undefined, rootElement);
    } else {
      return FilterTitles.factoryOld.pick(undefined, rootElement);
    }
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", FilterTitles.initAll);
    eventQBus.on("heureka.filterSection.loadAborted", FilterTitles.initAll);
  }

  static initAll(event?: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterTitles.template(rootElement)?.init();
  }

  protected init() {
    FilterTitleClickAction.on(this);
    SubmitOnChangeListener.on(
      this.elem,
      SubmitOnChangeListener.submitOnChange(fireFilterSubmit, SortingForm.factory.selector),
    );
    return this;
  }

  /*                       */

  private get footer() {
    return FilterTitle.footer(this.elem);
  }

  private set footer(footer) {
    if (footer) {
      this.elem.appendChild(footer.elem);
    }
  }

  private get values(): HTMLElement[] {
    return this.filters;
  }

  private set values(filtersToSort: HTMLElement[]) {
    appendAll(filtersToSort, this.elem);
    /*                                                    */
    const { footer } = this;
    this.footer = footer;
  }

  private prependValuesDense(filterToPrepend: HTMLElement) {
    const denseElem = this.filterTitlesWrapper()?.elem.querySelector(SELECTOR_DENSE) as HTMLElement;
    if (denseElem) {
      prependElement(filterToPrepend, denseElem);
    }
  }

  private set valuesDense(filterTitles: HTMLElement[]) {
    const destination = this.filterTitlesWrapper()?.elem.querySelector(SELECTOR_DENSE) as HTMLElement;
    if (destination) {
      clear(destination);
      appendAll(filterTitles, destination);
    }
  }

  private set valuesExpanded(filterTitles: HTMLElement[]) {
    const destination = this.filterTitlesWrapper()?.elem.querySelector(SELECTOR_EXPANDED) as HTMLElement;
    if (destination) {
      clear(destination);
      appendAll(filterTitles, destination);
    }
  }

  private filterTitlesWrapper() {
    return FilterTitles.factory.pick(".heureka_filterTitles");
  }

  sortValuesOld(sorter: FilterSorting | FilterSortingOld) {
    const sortedFilters = sorter.sortFiltersByQuery(this.values);
    if (sortedFilters) {
      this.values = sortedFilters;
    }
  }

  sortValues(sorter: FilterSorting) {
    const sortedFilters = sorter.sortFilterTitlesByQuery(this.filterTitles);

    const denseFilterTitles: HTMLElement[] = [];
    const expandedFilterTitles: HTMLElement[] = [];

    sortedFilters.forEach((filterTitle) => {
      if (filterTitle.referencedFilter && filterTitle.referencedFilter.isVisibleInDenseMode) {
        denseFilterTitles.push(filterTitle.elem);
      } else if (filterTitle.referencedFilter && !filterTitle.referencedFilter.isVisibleInDenseMode) {
        expandedFilterTitles.push(filterTitle.elem);
      }
    });

    /*                                   */
    const filterSortingFilterTitle = FilterTitle.filterId("find_filter_sorting")?.elem;
    if (filterSortingFilterTitle) {
      denseFilterTitles.unshift(filterSortingFilterTitle);
    }
    this.valuesDense = denseFilterTitles;
    this.valuesExpanded = expandedFilterTitles;
  }
}
